/* a:visited {
  color: #2196f3 !important;
} */
@font-face {
  font-family: "Digory Doodles";
  src: local("Digory Doodles"),
    url("../fonts/Digory_Doodles_PS.ttf") format("truetype");
  font-weight: regular;
}
@font-face {
  font-family: "Niconne Regular";
  src: local("Niconne Regular"),
    url("../fonts/Niconne-Regular.ttf") format("truetype");
  font-weight: regular;
}
@font-face {
  font-family: "Damion Regular";
  src: local("Damion Regular"),
    url("../fonts/Damion-Regular.ttf") format("truetype");
  font-weight: regular;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #ffffff;
  color: #101010 !important;
}
.sidebar .sidebar-brand-wrapper {
  width: 255px;
  height: 63px;
  position: fixed;
  border-bottom: 1px solid #e2e2e2;
}
.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  position: fixed;
  width: 255px;
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}
.m-t-70 {
  margin-top: 70px !important;
}
.iKPurz {
  overflow: hidden !important;
}
.iECIgJ {
  overflow-x: hidden !important;
}
.form-group label {
  color: #343a40 !important;
}
.form-control {
  border: 1px solid #b3b3b3 !important;
}
.sidebar .sidebar-brand-wrapper .sidebar-brand img {
  width: calc(255px - 80px);
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 12px;
  margin-top: 5px;
}
.d-uploader {
  width: 90% !important;
  height: 300px !important;
  overflow: hidden;
  background: white;
  border: 1px dotted #000;
}
._2_g61 {
  padding: 1rem;
  margin-top: 50px;
}
._2Jtxm ._1hzhf {
  color: #4d1616 !important;
}
.p-l {
  padding-left: 0px;
}
._53Ji7 {
  padding: 2rem 0.5rem !important;
}
.d-block {
  display: inline-block !important;
}
._hsN1w {
  color: #ffffff !important;
}
.b-b {
  border-bottom: 1px solid gray;
  padding-bottom: 8px;
}
.cBZXUy div:first-child {
  white-space: nowrap;
  overflow: visible !important;
  text-overflow: ellipsis;
}
.cus-i-s {
  text-align: center;
  margin: 10% auto;
  display: block;
}
.a-s {
  min-width: 60px !important;
  min-height: 50px !important;
  width: 80px;
  height: 70px;
  margin-top: 35px;
}
body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 150px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}
a:focus,
input:focus {
  border-color: #aaa;
  outline: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.react-autosuggest__container {
  position: relative;
  display: inline-block;
  padding: 20px 10px;
}
.container {
  display: flex;
}
.react-autosuggest__input {
  width: 100%;
  height: 40px;
  padding: 10px 10px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #227fe2;
  font-size: 10px;
  margin: 20px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    text-align: center;
    padding: 16px 26px;
  }
  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
    width: 70px;
  }
}
@media (min-width: 576px) {
  .modal-lg {
    max-width: 65%;
    margin: 30 px auto;
  }
}
.d-down {
  padding: 10px;
  border-bottom: 1px solid #c3c3c3;
}
.d-i {
  display: inline-block;
}
.f-r {
  float: right;
}
.m-r {
  margin-right: 15px;
}
.blink_me {
  float: right;
  color: #397cf6;
  font-weight: bold;
  transform: translate(-30%, -50%);
}

.header-bg {
  background: #2082f3;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
  z-index: 999 !important;
}
.react-confirm-alert-body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif !important;
  padding: 0px !important;
}
.react-confirm-alert-body > h1 {
  background: rgb(19 147 250);
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  font-size: 16px;
  padding: 10px;
  color: #fff;
}
.react-confirm-alert-button-group {
  display: block !important;
  padding: 5px;
  text-align: center;
  border-top: 1px solid #bbbbbb;
}
.mod-p {
  padding: 12px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: #d83232;
  font-weight: bold;
}
.mod-sp {
  padding: 12px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: #0f8e3c;
  font-weight: bold;
}
.react-confirm-alert-button-group > button {
  background: #28a745 !important;
  padding: 8px 20px !important;
  border-radius: 9px !important;
  font-size: 13px !important;
}
.bg-2 {
  background: url(../../assets/bg-3.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.loginbrac-bg {
  background: url(../../assets/ecipher_bg.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(20, 18, 18, 0.89);
  box-shadow: 0px 3px 7px 0px rgba(20, 18, 18, 0.89);
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.login-bg {
  background: url(../../assets/bg-5.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(20, 18, 18, 0.89);
  box-shadow: 0px 3px 7px 0px rgba(20, 18, 18, 0.89);
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#login-page .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 1rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #b3b3b3;
  border-radius: 25px;
}
#login-page .input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-left: none !important;
}
#login-page .input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.bg-overly {
  background: #000010;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
#overlyy {
  background: olive;
  opacity: 0.8;
}
.l-d-none {
  display: none;
}
.d-box {
  background: #ffffff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(20, 18, 18, 0.89);
  box-shadow: 0px 2px 4px 0px rgba(20, 18, 18, 0.89);
}
.auth .auth-form-light {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.9);
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.9);
}
.navbar
  .navbar-menu-wrapper
  .navbar-nav.navbar-nav-right
  .nav-item
  .dropdown
  .dropdown-toggle::after {
  content: "";
}
.dropdown .dropdown-toggle::after {
  content: "";
}
@media (min-width: 300px) and (max-width: 575px) {
  .brand-logo2 {
    width: 200px !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .brand-logo {
    width: 170px !important;
  }
  .l-d-none {
    display: block;
  }
  .d-box {
    background: #ffffff;
    border-radius: 20px;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(20, 18, 18, 0.89);
    box-shadow: 0px 2px 4px 0px rgba(20, 18, 18, 0.89);
  }
  h6 {
    font-size: 14px;
  }
  #login-page .input-group-text {
    padding: 0.875rem 1rem;
  }
}
.sidebar .nav.sub-menu {
  position: relative;
}

.sidebar > .nav .nav-item .sub-menu .nav-item .nav-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 35px;
  display: block;
  height: 100%;
  width: 2px;
  background: #4a4a4a;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active .menu-icon {
  color: #0071ae;
  background: transparent;
}
.sidebar > .nav .nav-item .sub-menu .nav-item .nav-link:hover:before {
  background: #0071ae;
}
.sidebar > .nav .nav-item .sub-menu .nav-item .menu-icon .nav-link:hover {
  color: #0071ae;
}
.stretch-card .text-white {
  background: #027ec1;
}
.rmsc {
  width: 100% !important;
  padding: 12px !important;
}
.rmsc .dropdown-container {
  padding: 3px;
}
.rms {
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
}
#c-view .form-control:disabled,
.form-control[readonly] {
  background-color: #f7f7f7;
  opacity: 1;
  color: #000000;
}
#c-view .form-control {
  border: 1px solid #cfcfcf !important;
}
.multi-select {
  max-width: 100% !important;
}
.MuiTypography-h6 {
  font-size: 1rem !important;
}
.css-133qd29 {
  padding: 4px;
}
.caliigraphy {
  font-size: 64px;
  font-family: "Niconne Regular";
  text-align: center;
  padding: 15px 10px;
  font-weight: bold;
}
select.form-control {
  color: #919191;
}

.stretch-card1 {
  display: inline-grid;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
}
#cart p {
  margin-bottom: 0.5rem;
}
.cartbtn {
  background: #ffaf00;
  padding: 2px;
  color: #ffffff;
  cursor: pointer;
}
.sidebar .nav.sub-menu {
  margin-bottom: 0;
  padding: 0 0 0 2rem;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}
.braccss {
  bottom: 0;
  position: absolute;
  background: rgb(0 123 255 / 58%);
  padding-top: 5px;
  border-radius: 0px 0px 20px 0px;
}
